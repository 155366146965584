<template>
  <div class="page">
    <iframe v-if="pxUrl" :src="pxUrl" frameborder="0"></iframe>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    pxUrl: ``
  }),
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    ...mapActions({})
  },
  created() {},
  mounted() {
    if (localStorage.token) {
      this.pxUrl = `${process.env.VUE_APP_PX_BASE_URL}asset?token=${localStorage.token}`
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  iframe {
    height: calc(100vh - 55px);
    width: 100%;
    margin: 0;
  }
}
</style>
